import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span } from "../../components/Core";

const LinkSocial = styled.a`
  color: ${({ theme }) => theme.colors.light} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 1.63px;
  transition: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.colors.light} !important;
    transform: translateY(-5px);
  }
`;

const About = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div>
                <img
                  src="/portraits/portfolio-about.jpg?nf_resize=fit&w=540"
                  alt=""
                  className="img-fluid"
                />
                {/* <img src={imgPhoto} alt="folio" className="img-fluid" /> */}
              </div>
            </Col>
            <Col lg="6">
              <div className="pl-lg-4 pt-5 pt-lg-0">
                <Title color="light" variant="secSm">
                  Daniella Herrera
                </Title>
                <Text
                  color="light"
                  className="mt-3 mt-lg-5"
                  css={`
                    line-height: 1.5;
                  `}
                >
                  I'm a little Mexicana from the south side of Chicago. I
                  currently live in //redacted// and work at //redacted//. There
                  is not much one needs to know about me. I'm a snob about
                  horror movies and punk music, but we all have our toxic
                  traits. Who am I as a designer? I am my experiences. I am my
                  worldview. I am the influence I let influence me. This website
                  is a collection of pixels that I create and uploaded when the
                  imposter syndrome subsided. Enjoy.
                </Text>
                <Text color="light" className="mt-4">
                  {/* I strive for a minimal and beautiful design. */}
                </Text>

                {/* <div className="mt-5">
                  <img src={imgSignLight} alt="sign" className="img-fluid" />
                </div> */}
                {/* <Text color="light" className="mt-3 font-weight-bold">
                  Bruce Ryan
                </Text>
                <Text color="light" variant="small">
                  Founder of Folio Digital
                </Text> */}
                <div className="mt-5 d-flex">
                  <LinkSocial
                    href="/HerreraDaniella_Resume.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Download Resume
                  </LinkSocial>

                  {/* <LinkSocial
                    href=""
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Github
                  </LinkSocial>
                  <LinkSocial
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Instagram
                  </LinkSocial>

                  <LinkSocial
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    Discord
                  </LinkSocial> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default About;
