import React from "react";
import { Link } from "gatsby";
import { Title } from "../Core";
// import logo from "../../assets/image/png/logo-second.png";

const Logo = ({ color = "front", height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <img
        src="/logos/logo-second.png?nf_resize=fit&w=80"
        width={80}
        alt=""
        className="img-fluid"
      />
      {/* <img src={logo} alt="logo" width="80" /> */}
    </Link>
  );
};

export default Logo;
